<template>
<div class="FormDateContainer d-flex">
      <div
              class="FormDate"
              :class="!dateValid ? 'is-invalid' : ''"
              @keyup.capture="updateValue"
      >
        <input
                v-if="showDay"
                ref="day"
                v-model="day"
                class="FormDate__input FormDate__input--day"
                :class="dayValid ? '' : 'is-invalid'"
                type="text"
                placeholder="dd"
                value="00"
                @input="updateDay"
                @blur="blurDay">
        <span
                v-if="showDay && showMonth"
                class="FormDate__divider"
        >/</span>
        <input
                v-if="showMonth"
                ref="month"
                v-model="month"
                class="FormDate__input FormDate__input--month"
                :class="monthValid ? '' : 'is-invalid'"
                type="text"
                placeholder="mm"
                value="00"
                @input="updateMonth"
                @blur="blurMonth">
        <span
                v-if="showYear && (showDay || showMonth)"
                class="FormDate__divider"
        >/</span>
        <input
                v-if="showYear"
                ref="year"
                v-model="year"
                class="FormDate__input FormDate__input--year"
                :class="yearValid ? '' : 'is-invalid'"
                type="text"
                value="0000"
                placeholder="yyyy"
                @input="updateYear"
                @blur="blurYear">
          <span v-if="showFormatted" class="small float-right ms-2">{{this.date}}</span>
      </div>

    <span v-if="clearButton" @click="clearValues" style="line-height: 38px" class="cursor-pointer ms-2"><i class="fa fa-times"></i></span>
</div>
</template>

<script>
  export default {
    name: `FormDate`,
    $_veeValidate: {
      // fetch the current value from the innerValue defined in the component data.
      value () {
        return this.outputValue;
      }
    },
    props: {
      value: {
        type: [Number, String],
        required: true,
      },
      showDay: {
        type: Boolean,
        default: true,
      },
      showMonth: {
        type: Boolean,
        default: true,
      },
      showYear: {
        type: Boolean,
        default: true,
      },
      showFormatted: {
        type: Boolean,
        default: false,
      },
      clearButton: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        date: null,
        outputValue: moment(this.value).format("YYYY-MM-DD"),
        day: `${this.value ? new Date(this.value).getDate() : ``}`,
        month: `${this.value ? new Date(this.value).getMonth() + 1 : ``}`,
        year: `${this.value ? new Date(this.value).getFullYear(): ``}`,
        dayValid: true,
        monthValid: true,
        yearValid: true,
      };
    },
    watch: {
      year(current, prev) {
        if (current > 9999) this.year = prev;
      },
    },
    methods: {
      validate() {
        if (!this.day.length || this.day.length < 2){
          this.dayValid = false;
        }
        if (!this.month.length || this.month.length < 2){
          this.monthValid = false;
        }
        if (!this.year.length || this.year.length < 4){
          this.yearValid = false;
        }
        this.validateDaysInMonth();
      },
      validateDaysInMonth() {
        const thirtyDayMonths = [4, 6, 9, 11];
        const thirtyOneDayMonths = [1, 3, 5, 7, 8, 10, 12];
        const twentyNineDayMonths = [2];

        if (thirtyDayMonths.includes(parseInt(this.month))){
          if (parseInt(this.day) > 30){
            this.dayValid = false;
          }
        }
        if (thirtyOneDayMonths.includes(parseInt(this.month))){
          if (parseInt(this.day) > 31){
            this.dayValid = false;
          }
        }
        if (twentyNineDayMonths.includes(parseInt(this.month))){
          if (parseInt(this.day) > 29){
            this.dayValid = false;
          }
        }
      },
      updateDay(validate=true) {
        this.dayValid = true;
        if (isNaN(this.day)){
          this.day = "00";
          this.dayValid = false;
          return;
        }

        if (!this.day.length){
            return;
        }
        if (parseInt(this.day) > 3 && parseInt(this.day) < 10) {
          if (this.day.length < 2){
            this.day = "0" + this.day;
          }
        }
        if (parseInt(this.day) > 31){
          this.day = "3";
          return;
        }
        if (this.day === "1" || this.day === "2" || this.day === "3" || this.day === "0"){
          return;
        }
        if (this.day.length > 2){
          this.day = this.day.substring(0, 2);
          return;
        }
        if (this.showMonth && validate) this.$refs.month.select();
        else if (this.showYear && validate) this.$refs.year.select();
        if (validate){
          this.validate();
        }
      },
      blurDay() {
        if (this.day === "0"){
          this.day = "";
          this.dayValid = false;
        }
        if (this.day.length === 1) {
          this.day = "0" + this.day;
        }
        if (this.day.length > 2){
          this.dayValid = false;
        }
        this.validate();
      },
      updateMonth() {
        this.monthValid = true;
        this.dayValid = true;
        this.updateDay(false);

        if (isNaN(this.month)){
          this.month = "00";
          this.monthValid = false;
          return;
        }
        if (this.month === "1"){
            return;
        }
        if (parseInt(this.month) > 12){
          this.month = "";
          this.monthValid = false;
        }
        if (this.month.length > 2){
          this.month = this.month.substring(0, 2);
          return;
        }
        if (!this.month.length || parseInt(this.month) < 2){
            return;
        }
        if (this.showYear){
            this.$refs.year.select();
        }
        this.validate();
      },
      blurMonth() {
        this.month = this.month.padStart(2, 0);
        this.validate();
      },
      updateYear() {
        this.yearValid = true;
        if (isNaN(this.year)){
          this.year = "0000";
          this.yearValid = false;
          return;
        }
        if (parseInt(this.year) < 1000){
          this.yearValid = false;
        }
        this.validate();
      },
      blurYear() {
        this.year = this.year.padStart(4, 0);
        this.validate();
      },
      clearValues() {
        this.day = null;
        this.month = null;
        this.year = null;
        this.$emit(`input`, null);
        this.$emit(`change`);
      },
      updateValue() {
        const timestamp = Date.parse(`${this.year}-${this.month}-${this.day}`);
        if (Number.isNaN(timestamp)){
          this.date = "Invalid date";
          this.outputValue = null;
          this.$emit(`input`, this.outputValue);
        } else {
          this.date = moment(timestamp).format("LL");
          this.outputValue = moment(timestamp).format("YYYY-MM-DD");
          this.$emit(`input`, this.outputValue);
          this.$emit(`change`);
        }
      },
    },
    computed: {
        dateValid: function () {
          return this.dayValid && this.monthValid && this.yearValid;
        },
    }
  };
</script>

<style lang="scss">
  .FormDate {
    $spacing: 0.45em;
    display: block;
    width: auto;
    position: relative;
    overflow: hidden;
    background-color: #ffffff;
    border: 1px solid #b0bfbf;
    border-radius: 0.25em;
    padding: 6px;
    // 1. Hide the spinner button in Chrome, Safari and Firefox.
    &__input {
      padding-top: 0px;
      padding-bottom: 0px;
      padding-right: 1px;
      padding-left: 0px;
      border: none;
      text-align: center;
      /* stylelint-disable-next-line property-no-vendor-prefix */
      -moz-appearance: textfield; // 1
      &::-webkit-inner-spin-button {
        display: none; // 1
      }
      &:focus {
        outline: none;
      }
      &--day,
      &--month {
        width: 3em;
      }
      &--year {
        width: 4em;
      }
    }
    &__divider {
      padding-top: 0px;
      padding-bottom: 0px;
      pointer-events: none;
    }

    .is-invalid{
        border: 1px solid #ff0000;
        border-radius: 5px;
        text-decoration: underline;
        text-decoration-color: #ff0000;
    }

    .small{
        padding-top: 2px;
    }
  }

  .FormDate.is-invalid{
    border: 1px solid #ff0000;
    border-radius: 5px;
  }
</style>
