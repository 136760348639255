var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[(_vm.updateExists)?_c('div',{staticClass:"p-2 sticky-top update-banner"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-auto ms-auto my-auto"},[_c('button',{staticClass:"btn btn-sm btn-light font-weight-bold",on:{"click":_vm.refreshApp}},[_c('i',{staticClass:"far fa-redo-alt me-2"}),_vm._v("Click to update ")])])])])]):_vm._e(),(
      _vm.$router.currentRoute.fullPath === '/' ||
      _vm.$router.currentRoute.fullPath === '/login'
    )?_c('front-end-nav',{attrs:{"path":_vm.$router.currentRoute.fullPath}}):_vm._e(),(
      _vm.$router.currentRoute.fullPath != '/events' &&
      _vm.$router.currentRoute.fullPath != '/' &&
      _vm.$router.currentRoute.fullPath != '/login'
    )?_c('main-nav'):_vm._e(),_c('router-view',{staticClass:"py-3",attrs:{"id":"router-view"}}),(_vm.user)?_c('snack-bar-alert'):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col my-auto"},[_c('p',{staticClass:"text-white font-weight-bolder mb-0"},[_vm._v(" Important: There is an update available for the Amchara Clients Portal! To receive the latest updates please reload the page or click the button ")])])}]

export { render, staticRenderFns }