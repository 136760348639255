<template>
  <div>
    <nav class="navbar navbar-expand-md navbar-light bg-white shadow-sm">
      <div class="container-fluid">
        <router-link class="navbar-brand" to="/">
          <img
            src="@/assets/images/site_logo.png"
            alt="Amchara"
            width="50"
          />
        </router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <!-- Left Side Of Navbar -->
          <ul class="navbar-nav ms-4 me-auto text-center"></ul>

          <!-- Right Side Of Navbar -->
          <ul class="navbar-nav ms-auto my-auto">
            <!-- <li class="nav-item my-auto me-3">
              <a
                class="nav-btn btn btn-sm btn-light"
                href="https://swandoola.com"
                ><i class="fad fa-globe me-2"></i>Amchara Websites</a
              >
            </li>

            <li class="nav-item my-auto me-3">
              <a
                class="nav-btn btn btn-sm btn-light"
                href="https://edu.swandoola.com/login"
                ><i class="fad fa-university me-2"></i>Educational Provider
                Login</a
              >
            </li>
            <li class="nav-item my-auto me-5">
              <a
                class="nav-btn btn btn-sm btn-light"
                href="https://suppliers.swandoola.com/login"
                ><i class="fad fa-truck me-2"></i>Suppliers Login</a
              >
            </li> -->
            <li class="nav-item my-auto">
              <router-link class="nav-btn btn btn-sm btn-success" to="/login"
                ><i class="fad fa-sign-in me-2"></i>Login</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>