<template>
  <div id="app">
    <div class="p-2 sticky-top update-banner" v-if="updateExists">
      <div class="container-fluid">
        <div class="row">
          <div class="col my-auto">
            <p class="text-white font-weight-bolder mb-0">
              Important: There is an update available for the Amchara Clients
              Portal! To receive the latest updates please reload the page or
              click the button
            </p>
          </div>
          <div class="col-auto ms-auto my-auto">
            <button
              @click="refreshApp"
              class="btn btn-sm btn-light font-weight-bold"
            >
              <i class="far fa-redo-alt me-2"></i>Click to update
            </button>
          </div>
        </div>
      </div>
    </div>
    <front-end-nav
      v-if="
        $router.currentRoute.fullPath === '/' ||
        $router.currentRoute.fullPath === '/login'
      "
      :path="$router.currentRoute.fullPath"
    ></front-end-nav>

    <main-nav
      v-if="
        $router.currentRoute.fullPath != '/events' &&
        $router.currentRoute.fullPath != '/' &&
        $router.currentRoute.fullPath != '/login'
      "
    ></main-nav>

    <router-view id="router-view" class="py-3" />
    <snack-bar-alert v-if="user"></snack-bar-alert>

    <!-- <notifications v-if="user" :user="user"></notifications> -->

    <!-- <timezone-check :user="user"></timezone-check> -->
  </div>
</template>
<script>
import MainNav from "./views/layouts/MainNav";
import FrontEndNav from "./views/layouts/FrontEndNav";
import SnackBarAlert from "./views/layouts/SnackBarAlert";
// import TimezoneCheck from "./views/layouts/TimezoneCheck";
// import Notifications from "./views/layouts/Notifications";

export default {
  metaInfo: {
    title: "Amchara Clients Portal",
    titleTemplate: "%s | Amchara - change for good",
  },
  data() {
    return {
      refreshing: false,
      registration: null,
      updateExists: false,
    };
  },
  computed: {
    user() {
      return this.$store.user;
    },
  },
  methods: {
    refreshUserData() {
      console.log("Refreshing user data");
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/refresh", {
          requester: "clients-portal",
        })
        .then(({ data }) => {
          this.$store.user = data.user;
          this.$store.token = data.token;

          localStorage.setItem("user", JSON.stringify(data));
          if (data.token) {
            this.$axios.defaults.headers.Authorization = `Bearer ${data.token.access_token}`;
          }
        });
    },
    showRefreshUI(e) {
      this.registration = e.detail;
      this.updateExists = true;
    },
    refreshApp() {
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      this.registration.waiting.postMessage("skipWaiting");
    },
  },
  created() {
    // Checking for service worker updates

    document.addEventListener("applicationUpdated", this.showRefreshUI, {
      once: true,
    });

    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (this.refreshing) return;
        this.refreshing = true;
        window.location.reload();
      });
    }

    // @todo fix this so it works with impersonation
    // this.$EventBus.$on("refreshUser", this.refreshUserData);
  },
  components: {
    MainNav,
    SnackBarAlert,
    // TimezoneCheck,
    // Notifications,
    FrontEndNav,
  },
};
</script>
<style lang="scss">
.update-banner {
  background: rgb(255, 115, 0);
}
</style>
