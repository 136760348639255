<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light bg-white shadow-sm">
      <div class="container-fluid">
        <router-link class="navbar-brand" :to="user ? '/dashboard' : '/'">
          <img src="@/assets/images/site_logo.png" alt="Amchara" width="36" />
        </router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div
          class="collapse navbar-collapse navbar-toggleable-sm"
          id="navbarSupportedContent"
        >
          <!-- Left Side Of Navbar -->
          <ul class="navbar-nav me-auto text-center my-auto mt-md-2">
            <li
              class="nav-item my-auto ms-4 me-4"
              v-if="user"
              v-tooltip.bottom="'Dashboard'"
            >
              <router-link class="nav-link text-success" to="/dashboard">
                <i
                  class="fad fa-tachometer-alt nav-icon-sized d-none d-md-inline"
                ></i>
                <span class="d-md-none">Dashboard</span>
              </router-link>
            </li>

            <li
              class="nav-item my-auto me-4"
              v-if="user"
              v-tooltip.bottom="'Retreat Bookings'"
            >
              <router-link class="nav-link text-success" to="/bookings">
                <i
                  class="fad fa-calendar nav-icon-sized d-none d-md-inline"
                ></i>
                <span class="d-md-none">Retreat Bookings</span>
              </router-link>
            </li>

            <li
              class="nav-item my-auto me-4"
              v-if="user"
              v-tooltip.bottom="'Treatments'"
            >
              <router-link class="nav-link text-success" to="/treatments">
                <i class="fad fa-syringe nav-icon-sized d-none d-md-inline"></i>
                <span class="d-md-none">Treatments</span>
              </router-link>
            </li>

            <!-- <li
              class="nav-item my-auto me-4"
              v-if="user"
              v-tooltip.bottom="'Preferences'"
            >
              <router-link class="nav-link text-success" to="/preferences">
                <i class="fad fa-glass nav-icon-sized d-none d-md-inline"></i>
                <span class="d-md-none">Retreat Preferences</span>
              </router-link>
            </li> -->
            <!--
            <li
              class="nav-item my-auto me-4"
              v-if="user"
              v-tooltip.bottom="'Amchara 360'"
            >
              <router-link class="nav-link text-success" to="/360">
                <i class="fad fa-circle nav-icon-sized d-none d-md-inline"></i>
                <span class="d-md-none">Amchara 360</span>
              </router-link>
            </li> -->

            <li
              class="nav-item my-auto me-4"
              v-if="user"
              v-tooltip.bottom="'Retreat Info'"
            >
              <router-link class="nav-link text-success" to="/retreat-info">
                <i
                  class="fad fa-umbrella-beach nav-icon-sized d-none d-md-inline"
                ></i>
                <span class="d-md-none">Retreat Info</span>
              </router-link>
            </li>

            <li
              class="nav-item my-auto me-4"
              v-if="user"
              v-tooltip.bottom="'Contact Info'"
            >
              <router-link class="nav-link text-success" to="/contact">
                <i class="fad fa-user nav-icon-sized d-none d-md-inline"></i>
                <span class="d-md-none">Contact Info</span>
              </router-link>
            </li>
          </ul>

          <!-- Right Side Of Navbar -->
          <ul class="navbar-nav ms-auto d-none d-md-flex my-auto">
            <!-- Authentication Links -->

            <li class="nav-item my-auto me-4" v-if="!user">
              <router-link class="nav-btn btn btn-primary" to="/login"
                ><span class="text-white">Login</span></router-link
              >
            </li>

            <li class="nav-item my-auto dropdown mx-4" v-if="user">
              <a
                id="navbarDropdown"
                class="dropdown-toggle"
                href="#"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span>{{ user.name }}</span>
                <span class="caret"></span>
              </a>

              <div
                class="dropdown-menu dropdown-menu-right mt-2"
                style="z-index: 1021"
                aria-labelledby="navbarDropdown"
              >
                <router-link class="dropdown-item" to="/settings"
                  >Settings</router-link
                >
                <div class="dropdown-divider"></div>
                <!-- <router-link
                    class="dropdown-item"
                    to="/billing"
                    v-if="user && $can('manage billing')"
                >Billing</router-link>
                <div
                    class="dropdown-divider"
                    v-if="user && $can('manage billing')"
                ></div> -->
                <a
                  class="dropdown-item"
                  href="https://amchara.com/privacy"
                  target="_blank"
                  >Privacy</a
                >
                <a
                  class="dropdown-item"
                  href="https://amchara.com/terms"
                  target="_blank"
                  >Terms</a
                >
                <div class="dropdown-divider"></div>
                <button
                  type="button"
                  class="dropdown-item"
                  @click="logout()"
                  v-if="user"
                >
                  Logout
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      notificationsCount: 0,
      showSupport: false,
    };
  },

  computed: {
    user() {
      return this.$store.user;
    },
    path() {
      return this.$router.currentRoute.fullPath;
    },
  },
  methods: {
    logout() {
      localStorage.removeItem("user");
      if (localStorage.getItem("googleLogin")) {
        var auth2 = gapi.auth2.getAuthInstance();
        auth2.signOut().then(function () {
          console.log("User signed out.");
        });
      }
      localStorage.removeItem("googleLogin");
      location.href = "/";
    },
    fetchNotificationsCount() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/notifications/unread")
        .then(({ data }) => {
          this.notificationsCount = data.notifications.length;
        });
    },
    goToCart() {
      this.$router.push("/cart");
    },
    showNotifications() {
      this.$EventBus.$emit("showNotifications");
    },
  },
  mounted() {
    if (this.user) {
      // this.fetchNotificationsCount();
    }

    var _this = this;

    this.$EventBus.$on("notificationsRead", function () {
      _this.notificationsCount = 0;
    });
  },
  filters: {
    acronym(name) {
      var str = name;
      var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
      var acronym = matches.join(""); // JSON

      return acronym;
    },
  },
  components: {},
};
</script>

<style lang="scss">
.navbar-light .navbar-nav .nav-link {
  color: #03006b;
}

.notification-pill {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 99px;
  width: 4.5rem;
  height: auto;
  background-color: #f4f6f6;
  color: #495a5a;
  font-weight: 600;
  font-size: 0.8rem;
}

.nav-icon-sized {
  font-size: 1.5rem;
}

.notification-pill {
  font-size: 0.75rem;
}

.notification-pill i {
  font-size: 1rem;
}
</style>
