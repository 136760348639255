import Vue from 'vue'
import VueRouter from 'vue-router'

import settings from "@/router/settings";

import VueMeta from 'vue-meta'


Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
    // Logged out views
    {
        path: '/',
        name: 'Home',
        component: () =>
            import( /* webpackChunkName: "home-page" */ '../views/pages/Home.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: () =>
            import( /* webpackChunkName: "login" */ '../views/auth/Login.vue')
    },
    {
        path: '/login/:token',
        name: 'LoginSplash',
        component: () =>
            import( /* webpackChunkName: "login-splash" */ '../views/auth/LoginSplash.vue')
    },

    // Logged in views
    {
        path: '/dashboard',
        name: 'DashboardView',
        component: () =>
            import( /* webpackChunkName: "dashboard-view" */ '../views/areas/dashboard/Index.vue'),
        meta: {
            auth: true
        },
    },
    // Bookings
    {
        path: '/bookings',
        name: 'BookingsIndex',
        component: () =>
            import( /* webpackChunkName: "bookings-index" */ '../views/areas/bookings/Index.vue'),
        meta: {
            auth: true
        },
    },

    {
        path: '/bookings/:id',
        name: 'BookingsView',
        component: () =>
            import( /* webpackChunkName: "bookings-view" */ '../views/areas/bookings/View.vue'),
        meta: {
            auth: true
        },
    },

    {
        path: '/forms/:id',
        name: 'FormsView',
        component: () =>
            import( /* webpackChunkName: "forms-view" */ '../views/areas/forms/Form.vue'),
        meta: {
            auth: true
        },
    },

    {
        path: '/room-bookings/:id/pay',
        name: 'RoomBookingsPay',
        component: () =>
            import( /* webpackChunkName: "bookings-pay" */ '../views/areas/bookings/RoomBookingPay.vue'),
        meta: {
            auth: true
        },
    },

    // Retreat Info
    {
        path: '/retreat-info',
        name: 'RetreatInfo',
        component: () =>
            import( /* webpackChunkName: "retreat-info" */ '../views/areas/retreat-info/Index.vue'),
        meta: {
            auth: true
        },
    },

    // Treatments
    {
        path: '/treatments',
        name: 'TreatmentsIndex',
        component: () =>
            import( /* webpackChunkName: "treatments-index" */ '../views/areas/treatments/Index.vue'),
        meta: {
            auth: true
        },
    },

    // Treatments
    {
        path: '/treatments/create',
        name: 'TreatmentsCreate',
        component: () =>
            import( /* webpackChunkName: "treatments-create" */ '../views/areas/treatments/Create.vue'),
        meta: {
            auth: true
        },
    },

    {
        path: '/treatments/:id',
        name: 'TreatmentsView',
        component: () =>
            import( /* webpackChunkName: "treatments-view" */ '../views/areas/treatments/View.vue'),
        meta: {
            auth: true
        },
    },

    {
        path: '/treatments/:id/Pay',
        name: 'TreatmentsPay',
        component: () =>
            import( /* webpackChunkName: "treatments-pay" */ '../views/areas/treatments/Pay.vue'),
        meta: {
            auth: true
        },
    },

    {
        path: '/preferences',
        name: 'PreferencesIndex',
        component: () =>
            import( /* webpackChunkName: "preferences-index" */ '../views/areas/preferences/Index.vue'),
        meta: {
            auth: true
        },
    },
    // Contact
    {
        path: '/contact',
        name: 'ContactIndex',
        component: () =>
            import( /* webpackChunkName: "contact-index" */ '../views/areas/contact/Index.vue'),
        meta: {
            auth: true
        },
    },
    // Three Sixty
    {
        path: '/360',
        name: 'ThreeSixtyIndex',
        component: () =>
            import( /* webpackChunkName: "three-sixty-index" */ '../views/areas/360/Index.vue'),
        meta: {
            auth: true
        },
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    // linkActiveClass: "text-dark",
    // linkExactActiveClass: "text-dark",
})

router.beforeEach((to, from, next) => {
    const loggedIn = localStorage.getItem('user')
    if (to.matched.some(record => record.meta.auth) && !loggedIn) {
        next('/login')
        return
    }

    if (to.path == '/login' || to.path == '/') {
        if (loggedIn) {
            next('/dashboard')
        }
    }
    next()

})

export default router